import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Engineering for Communities`}</h1>
    <p>{`This site was not just created to make it easy for content contributors to create their own pages with minimal developer oversight.
It's explicitly meant to be easy for engineers to extend. MakerDAO is about building better money, and this requires that the tools
we use are openly accessible and can be used without permission. We're taking that a step further here, by making our community tools
not only open, but `}<strong parentName="p">{`easy`}</strong>{` to use, complete with an entire section dedicated to technical education.`}</p>
    <p>{`Here we explain our experience of building a scalable community website that can handle multiple different languages;
many different kinds of contributors; many different ways and means of contributing; why we've structured things as we have; how you
can extend anything; and how you can dive deeper into the internals of how specific modules and components work. You'll also learn how to
leverage our design system - `}<a parentName="p" {...{
        "href": "https://github.com/makerdao/dai-ui"
      }}>{`DAI-UI`}</a>{` - to build slick, smooth and optimised user interfaces with minimal effort.`}</p>
    <p>{`It's great to speak the language of online community building, but one needs solid technical foundations on which to build truly transformative
ways of interacting and relating. This site is a building-block which sits on top of our open smart contracts that
define the actual protocols for a better kind of money, in order to show you how high the temple pillars can really reach.`}</p>
    <p>{`This is just the beginning of your journey. Once you've seen how easy it is to work with our technical community tools, and how they
interact with our Developer Experience and Design work, like DAI-UI, we hope you'll fall down the rabbit hole
and begin to figure out the deeper architectural challenges and intricacies of what "better money" might really mean for all of us.`}</p>
    <Button to="https://docs.makerdao.com" primary mdxType="Button">
  Protocol Documentation
    </Button>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      